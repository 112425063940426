import React from 'react';
import ReactWebChat from './WebChat';
import './fabric-icons-inline.css';
import './MinimizableWebChat.css';
import { connect } from 'react-redux';

class MinimizableWebChat extends React.Component {
  constructor(props) {
    super(props);

    this.handleFetchToken = this.handleFetchToken.bind(this);
    this.handleMaximizeButtonClick = this.handleMaximizeButtonClick.bind(this);
    this.handleMinimizeButtonClick = this.handleMinimizeButtonClick.bind(this);
    this.handleSwitchButtonClick = this.handleSwitchButtonClick.bind(this);
    this.printChatContents = this.printChatContents.bind(this);

    this.state = {
      minimized: true,
      newMessage: false,
      side: 'right',
      // store,
      /*
      styleSet: createStyleSet({
        backgroundColor: 'Transparent',
        hideUploadButton: true,
      }),
      */
      token: null
    };
  }

  async handleFetchToken() {
    if (!this.state.token) {
      const res = await fetch(process.env.REACT_APP_TOKEN_URL, { method: 'POST' });
      const { token } = await res.json();

      this.setState(() => ({ token }));
    }
  }

  setNewMessage = () => { this.setState({newMessage: true}) };
  

  printChatContents() {
/*
    var newIframe = document.createElement('iframe');
    newIframe.width = '1px';
    newIframe.height = '1px';
    newIframe.src = 'about:blank';

    // for IE wait for the IFrame to load so we can access contentWindow.document.body
    newIframe.onload = function() {
        var script_tag = newIframe.contentWindow.document.createElement("script");
        script_tag.type = "text/javascript";
        var script = newIframe.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
        script_tag.appendChild(script);

        newIframe.contentWindow.document.body.innerHTML = document.querySelector('div[role="log"]').innerHTML;
        newIframe.contentWindow.document.body.appendChild(script_tag);

        // for chrome, a timeout for loading large amounts of content
        setTimeout(function() {
            newIframe.contentWindow.Print();
            newIframe.contentWindow.document.body.removeChild(script_tag);
            newIframe.parentElement.removeChild(newIframe);
        }, 200);
    };
    document.body.appendChild(newIframe);
*/
    var content = document.querySelector('ul[role="list"]').innerHTML;
    var mywindow = window.open('', 'Print', 'height=600,width=800');

    mywindow.document.write('<html><head><title>Print</title>');
    var styles = document.getElementsByTagName('style');
    for (var i = 0; styles.length; i++) {
      if (styles[i] !== undefined && styles[i].outerHTML !== undefined) {
        mywindow.document.write(styles[i].outerHTML);
      }
      
    }

    mywindow.document.write('</head><body >');
    mywindow.document.write(content);
    mywindow.document.write('</body></html>');

    mywindow.document.close();
    mywindow.focus()
    mywindow.print();
    mywindow.close();
    return true;

    /*    

    var disp_setting="toolbar=yes,location=no,";
    disp_setting+="directories=yes,menubar=yes,";
    disp_setting+="scrollbars=yes,width=650, height=600, left=100, top=25";
    var content_vlue = document.querySelector('ul[role="list"]').innerHTML;
    var docprint=window.open("","",disp_setting);
    docprint.document.open();
    docprint.document.write('<html>');
    docprint.document.write('<head><title>My Title</title>');
    docprint.document.write('</head><body><center>');
    docprint.document.write(content_vlue);
    docprint.document.write('</center></body></html>');
    //setTimeout(function(){docprint.print();},1000);
    docprint.document.close();
    docprint.focus();
    */
    /*
    var printContents = document.querySelector('ul[role="list"]').innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    */
  }

  handleMaximizeButtonClick() {
    this.setState(() => ({
      minimized: false,
      newMessage: false
    }));
  }

  handleMinimizeButtonClick() {
    this.setState(() => ({
      minimized: true,
      newMessage: false
    }));
  }

  handleSwitchButtonClick() {
    this.setState(({ side }) => ({
      side: side === 'left' ? 'right' : 'left'
    }));
  }
  
  render() {
    const {
      props: { 
        avatarImage,
        avatarIanClass,
        dispatch
      }, 
      state: {
        minimized,
        newMessage,
        side,
        // store,
        // styleSet,
        token
      }
    } = this;

    return (
      <div className="minimizable-web-chat">
        {
          minimized ?
            <button
              className="maximize"
              onClick={ this.handleMaximizeButtonClick }
            >
              <span className={ token ? 'ms-Icon ms-Icon--MessageFill' : 'ms-Icon ms-Icon--Message' } />
              {
                newMessage &&
                  <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />
              }
            </button>
          :
            <div
              className={ side === 'left' ? 'chat-box left' : 'chat-box right' }
            >
              <header>
                <div className="filler" />
{/*                 <button
                  className="print"
                  onClick={ this.printChatContents }
                >
                  <span className="ms-Icon ms-Icon--Print" />
                </button>
 */}                <button
                  className="switch"
                  onClick={ this.handleSwitchButtonClick }
                >
                  <span className="ms-Icon ms-Icon--Switch" />
                </button>
                <button
                  className="minimize"
                  onClick={ this.handleMinimizeButtonClick }
                >
                  <span className="ms-Icon ms-Icon--ChromeMinimize" />
                </button>
              </header>
              <ReactWebChat
                appDispatch={ dispatch }
                className="react-web-chat"
                onFetchToken={ this.handleFetchToken }
                setNewMessage={ this.setNewMessage }
                // store={ store }
                // styleSet={ styleSet }
                token={ token }
              />
              <img alt="avatar" src={process.env.PUBLIC_URL + avatarImage } className={ side === 'left' ? 'avatar right'+avatarIanClass : 'avatar left'+avatarIanClass } />
            </div>
        }
      </div>
    );
  }
}
const mapStateToProps = function(state) {
  return {
    avatarImage: state.avatarImage,
    avatarIanClass: state.avatarIanClass
  }
}
export default connect(mapStateToProps)(MinimizableWebChat)
