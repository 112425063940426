const SET_AVATAR = 'SET_AVATAR';

function setAvatar(avatar, ianclass) {
    return {
        type: SET_AVATAR,
        payload: { avatar, ianclass }
    }
}

export {
    SET_AVATAR,
    setAvatar
}