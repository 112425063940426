import memoize from 'memoize-one';
import React from 'react';
import ReactWebChat, { createDirectLine, createStore, createStyleSet } from 'botframework-webchat';

import './WebChat.css';
import dispatchIncomingActivityMiddleware from './dispatchIncomingActivityMiddleware';


export function newAttachmentMiddleware() {
    return () => next => card => {
      if (card.attachment.contentType === 'application/vnd.microsoft.card.custom'){
        card.attachment.contentType = 'application/vnd.microsoft.card.adaptive'
      }
      return next(card)  
    }
}

export default class extends React.Component {
  constructor(props) {
     super(props);

    this.createDirectLine = memoize(token => createDirectLine({ token }));

    this.store = createStore({}, 
      dispatchIncomingActivityMiddleware(props.appDispatch, props.setNewMessage),
      ({ dispatch }) => next => action => {
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
              name: 'webchat/join',
              value: {
                language: window.navigator.language
              }
            }
          });
        } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
          const { activity } = action.payload;
          if (
            activity.type === 'message'
            && activity.from.role === 'bot') {
            props.setNewMessage();
          }
        }

        return next(action);
      }      
    );

    this.state = {
      styleSet: createStyleSet({
        backgroundColor: 'Transparent',
        hideUploadButton: true,
      })
    };
  }

  componentDidMount() {
    !this.props.token && this.props.onFetchToken();
  }

  render() {
    const {
      // props: { className, store, token },
      props: { className, token },
      state: { styleSet }
    } = this;

    return (
      token ?
        <ReactWebChat
          attachmentMiddleware={ newAttachmentMiddleware() }
          className={ `${ className || '' } web-chat` }
          directLine={ this.createDirectLine(token) }
          store={ this.store }
          styleSet={ styleSet }
        />
      :
        <div className={ `${ className || '' } connect-spinner` }>
          <div className="content robot-msg">
            <div className="icon">
              <span className="ms-Icon ms-Icon--Robot" />
            </div>
            <p>Please wait while we are connecting.</p>
          </div>
        </div>
    );
  }
}
