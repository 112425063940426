import { SET_AVATAR } from './actions';

const DEFAULT_STATE = {
    avatarImage: '/probation-resources/chatbot/static/img/chief.png',
    avatarIanClass: ''
};

export default function (state = DEFAULT_STATE, { payload, type }) {
    switch (type) {
        case SET_AVATAR:
            state = { ...state, avatarImage: payload.avatar, avatarIanClass: payload.ianclass };
            break;
        default: break;
    }
    return state;
}